.fill-15-field-margin {
    width: calc(15% - 57px);
}

.fill-33-field-margin {
    width: calc(33.3% - 38px);
}

.fill-50-field-tooltip {
    width: calc(50% - 29px);
    padding-right: 29px;
}

.fill-50-field-tooltip-left {
    width: calc(50% - 14.5px);
    padding-right: 14.5px;
}

.fill-50-field-tooltip-right {
    width: calc(50% - 29px);
    padding-right: 29px;
    margin-left: 42.5px;
}

#expense-registration .buttons-expense-registration {
    display: flex;
    justify-content: flex-end;
}

#expense-registration .buttons-expense-registration>*+* {
    margin-left: 8px;
}

#expense-registration {
    display: flex;
    flex-direction: column;
    height: 100%;
}