.LayoutWrapper {
  display: flex;
}

.LayoutWrapper .ScreenContent {
  width: 100%;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawer-content {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 33px;
  background-color: var(--white);

  height: calc(100vh - 100px);
  overflow-y: auto;
}
