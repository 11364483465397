.month-calendar-field:nth-child(2) {
  order: 4;
}

/* TODO botar amrgin entre os cards */
.k-chart,
.kwidget {
  width: 100%;
}

/* FIXME search for more sustainable stylish for remove vertical lines of graph */
.k-chart-surface>svg>g>g>g:first-of-type>path:not(:last-child) {
  display: none;
}

.k-card-horizontal {
  width: calc(25% - 12px);
  min-width: 170px;
  height: 125px;
}

.k-card-horizontal .k-card-image {
  width: auto;
  height: 125px;
  opacity: 0.5;
}

.k-card-horizontal .k-vbox {
  width: 100%;
  min-width: 130px;
}

.k-card-body {
  text-align: right;
}

.k-card-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.k-card-actions {
  padding: 0px 16px;
}

.k-card-actions .k-card-title {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.k-card {
  box-shadow: 2px 2px 6px #cdcdcd;
}

.container-card {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 2px 2px 6px #cdcdcd;
  min-width: calc(100%);
  width: 100%;
}

.container-card .card-content text {
  font-size: 11px !important;
}

.container-card .valueInReal {
  align-self: center;
  white-space: nowrap;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-right: 20px;
  font-size: 12px !important;
}

.container-card .card-content {
  display: flex;
  justify-content: flex-end;
  color: #6f6f6f;
  font-weight: 400 !important;
  font-size: 18px !important;
  margin-right: 15px;
}

.container-card .card-header {
  border-bottom: 1px solid;
  padding: 10px 15px;
  font-size: 24px !important;
  font-weight: 700 !important;
  margin: 0;
}

.container-card .card-content {
  padding: 10px;
}

.buttons-home {
  display: flex;
  justify-content: flex-end;
  margin-block-end: 32px;
}