.load-screen {
  height: 100%;
  background-color: var(--white);
  text-align: center;
}

.loader-wrapper {
  position: relative;
  top: calc(50% - 35px);
}
