.popup-info-content {
  margin: 0;
  background-color: #f6f6f6;
  color: #646666;
  padding: 20px;
  font-size: 13px !important;
}

.custom-button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.custom-button img {
  width: 17px;
}

.info-popup {
  box-shadow: 6px 6px 10px rgb(0 0 0 / 15%);
  max-width: 400px;
}
