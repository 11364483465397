.tooltip-popup-threePoints {
  position: relative;
  display: inline;
}

.tooltip-popup-threePoints .tooltip-threePoints-popuptext {
  visibility: hidden;
  border-radius: 6px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: 5px;
  right: 10px;
  font-family: 'Lato', Helvetica, sans-serif, Arial;
  font-weight: 300;
  color: white;
  background-color: #1ba7d0;
  vertical-align: auto;
  margin-right: 45px;
}

.tooltip-popup-threePoints:hover .tooltip-threePoints-popuptext {
  visibility: visible;
}

.k-fab-item.k-text-right {
  margin-top: 7px !important;
  margin-bottom: revert;
  height: 32px;
  width: 34px;
}

.tooltip-popup-threePoints.k-fab-items {
  margin: 0;
  margin-left: 7px;
  padding: 0px 0;
  display: flex;
}

.k-chart-shared-tooltip .k-chart-shared-tooltip-marker {
  width: 15px;
  height: 3px;
  vertical-align: middle;
}


.icone-pix {
  margin-bottom: -2px !important;
  margin-left: -22px !important;
  margin-right: 10px !important;
  width: 12px !important;
}


.icone-excluir {
  margin-bottom: -4px !important;
  margin-left: -22px !important;
  margin-right: 10px !important;
  width: 12px !important;
}