#contract-list .buttons-contract-list {
  display: flex;
  justify-content: flex-end;
}

#contract-list .buttons-contract-list>*+* {
  margin-left: 8px;
}

#contract-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.k-i-circle::before {
  font-size: xx-large;
}