.day-calendar-field {
  width: unset;
}

.day-calendar .react-datepicker {
  border-radius: 0;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 18%);
}

.day-calendar-field:hover input {
  cursor: pointer;
}

.day-calendar * {
  text-transform: uppercase;
  background-color: white;
  font-size: 14px !important;
  color: #333;
}

/* TODO terminar de corrigir isso */
.day-calendar .react-datepicker__header .react-datepicker__day-name {
  font-size: 0 !important;
  box-sizing: border-box;
  width: 40px;
  height: 31px;
  margin: 0;
}

.day-calendar .react-datepicker__header .react-datepicker__day-name::first-letter {
  font-size: 14px;
}

.day-calendar .react-datepicker__navigation-icon::before,
.day-calendar .react-datepicker__triangle{
  display: none;
}

.day-calendar span.react-datepicker__navigation-icon--previous,
.day-calendar span.react-datepicker__navigation-icon--next {
  width: 0;
  height: 0;

  transform: unset;

  border: 6px solid transparent;
}

.day-calendar span.react-datepicker__navigation-icon--previous {
  border-right-color: var(--primary-color);
}

.day-calendar span.react-datepicker__navigation-icon--next {
  border-left-color: var(--primary-color);
}

.day-calendar .react-datepicker__day {
  display: inline-flex;
  height: 40px;
  width: 40px;
  font-weight: 300 !important;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.day-calendar .react-datepicker__day,
.day-calendar .react-datepicker__month {
  margin: 0;
}

.day-calendar .react-datepicker__day:hover {
  background-color: #F4F4F4;
}

.day-calendar .react-datepicker__day:active {
  background-color: var(--secondary-color) !important;
}

.day-calendar .react-datepicker__day--selected {
  border-radius: 0;
  box-shadow: inset 0 0 0 1px #9b6115 !important;
}

.day-calendar .react-datepicker__today-button {
  padding: 10px 0;
  color: var(--primary-color);
}

.day-calendar .react-datepicker__today-button:hover {
  text-decoration: underline;
}

.day-calendar .react-datepicker__current-month,
.day-calendar .react-datepicker__navigation {
  height: 26px;
  margin: 5px 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-calendar .react-datepicker__header {
  padding: 0;
  padding-bottom: 8px;
}
