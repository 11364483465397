.image .k-avatar {
  min-width: 240px;
}

.image .k-avatar span.k-avatar-text {
  font-size: 16px !important;
  display: flex;
}

.image .k-avatar-solid.k-avatar-primary {
  color: #424242;
  border: 1px dashed #999999;
  text-align: center;
}