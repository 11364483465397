#relationship-list .buttons-relationship-list {
  display: flex;
  justify-content: flex-end;
}

#relationship-list .buttons-relationship-list>*+* {
  margin-left: 8px;
}

#relationship-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.k-i-circle::before {
  font-size: xx-large;
}

.link {
  color: var(--primary-color) !important;
  display: block;
  text-align: end;
  height: 20px;
}