.navbar-item {
  width: 100%;
}

.navbar-item > span {
  margin: 16px;
  color: #b0bec5;
}

.navbar-item p {
  text-align: right;
  line-height: 37px;
  margin: 0;
}

.navbar-item .fill-all span.k-avatar-text,
.navbar-item .fill-all span.k-avatar-text img {
  height: 100%;
}
