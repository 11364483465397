.toggle-invoice {
  margin-top: 20px;
}

.toggle-invoice li[aria-expanded='false'] {
  border-bottom: 1px solid rgba(112, 153, 166, 0.7);
}

.toggle-invoice li[aria-expanded='false']:hover {
  border-bottom: 1px solid #7099a6;
}

.panel-bar-toggle .k-panelbar>.k-item>.k-link,
.k-panelbar>.k-panelbar-header>.k-link {
  padding-left: 0;
}

.toggle-invoice>.k-panelbar>.k-item>.k-link {
  padding-bottom: 10px;
  line-height: 0px;
}

.toggle-invoice .k-panelbar .k-panelbar-toggle {
  color: #929b9e !important;
}

.toggle-invoice .k-panelbar {
  border-width: 0 !important;
  border-style: none !important;
}

/* TODO change this from id to class */
#line {
  width: 100%;
  border-bottom: #949a9c 1px solid;
}

.toggle-invoice .k-content {
  background-color: var(--white) !important;
}

.panel-bar-toggle {
  width: '100%';
  border-bottom: '#92b0ba 1px solid';
}

.toggle-invoice span.k-link {
  background-color: transparent !important;
}

.toggle-invoice .k-panelbar {
  background-color: unset;
}

.toggle-invoice .k-panelbar-item-text {
  width: 100%;
  font-size: 18px !important;
  color: #7099a6;
  font-weight: 300 !important;
}

.toggle-invoice .k-panelbar-item-text:hover {
  font-weight: 400 !important;
}

.toggle-invoice .input-field .internal-icon {
  display: flex;
  align-items: center;
  padding: 0 16px;
}