.general-button {
  cursor: pointer;
  font-weight: 600 !important;
}

.general-button input[type='file'] {
  display: none;
}

.box-button {
  border: 1px solid;
  padding: 5px 12px 6px 12px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
