.config-components-popup-button *,
ul.k-fab-items * {
  color: white;
}

.config-components-popup-button .k-fab-primary {
  background-color: var(--primary-color);
  outline: var(--primary-color);
}

.config-components-popup-button .k-fab-primary:hover {
  background-color: var(--primary-color-hover);
  outline: var(--primary-color-hover);
}

ul.k-fab-items .k-fab-primary {
  background-color: #00a6d2;
  outline: #00a6d2;
}

ul.k-fab-items .k-fab-primary:hover {
  background-color: #008eb6;
  outline: #008eb6;
}

.k-fab-secondary {
  background-color: #e16996;
  outline: #e16996;
}

.k-fab-secondary:hover {
  background-color: #cc5480;
  outline: #cc5480;
}

.k-fab-tertiary {
  background-color: #5da57d;
  outline: #5da57d;
}

.k-fab-tertiary:hover {
  background-color: #3f8a61;
  outline: #3f8a61;
}

.k-fab-inherit {
  color: white;
  background-color: #ea9900;
  outline: #ea9900;
}

.k-fab-inherit:hover {
  background-color: #d18904;
  outline: #d18904;
}

.k-fab-info {
  background-color: #7099a6;
  outline: #7099a6;
}

.k-fab-info:hover {
  background-color: #5d8b99;
  outline: #5d8b99;
}

.k-fab-success {
  background-color: #00b248;
  outline: #00b248;
}

.k-fab-success:hover {
  background-color: #029e41;
  outline: #029e41;
}

.k-fab-error {
  background-color: #ff3b3d;
  outline: #ff3b3d;
}

.k-fab-error:hover {
  background-color: #e72a2d;
  outline: #e72a2d;
}

.k-fab-warning {
  background-color: #f7b91f;
  outline: #f7b91f;
}

.k-fab-warning:hover {
  background-color: #e7ad19;
  outline: #e7ad19;
}

.k-fab-dark {
  background-color: #6f6f6f;
  outline: #6f6f6f;
}

.k-fab-dark:hover {
  background-color: #5f5f5f;
  outline: #5f5f5f;
}

.k-fab-ligth {
  background-color: #596cae;
  outline: #596cae;
}

.k-fab-ligth:hover {
  background-color: #4b5d9e;
  outline: #4b5d9e;
}

.k-fab-pdf {
  background-color: #B30B00;
  outline: #B30B00;
}

.k-fab-pdf:hover {
  background-color: #C70C00;
  outline: #C70C00;
}

.k-fab-pix {
  background-color: #32bcad;
  outline: #32bcad;
}

.k-fab-pix:hover {
  background-color: #189788;
  outline: #189788;
}

.k-fab-bluepurple {
  background-color: #6372a7;
  outline: #6372a7;
}

.k-fab-bluepurple:hover {
  background-color: #596AA9 !important;
  outline: #596AA9 !important;
}

.config-div-button {
  display: flex !important;
  justify-content: space-between;
}

.config-components-popup-button .k-fab-icon {
  width: 43px;
  height: 43px;
  font-size: 20px !important;
}

.k-fab {
  box-shadow: none;
}

.k-fab-circle.k-fab-sm {
  width: calc(1.4285714286em + 9px);
  height: calc(1.4285714286em + 9px);
}

.k-fab-items {
  margin: 2px;
  display: flex;
}

.config-components-popup-button .k-pos-fixed {
  position: inherit !important;
}

.config-components-popup-button .k-fab-md {
  padding: 0;
}