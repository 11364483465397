.label-wrapper {
  margin-bottom: 4px;
}

.label-wrapper .config-icon-plus {
  width: 17px;
  height: 17px;
  margin-left: 4px;
  background: url('../../../../assets/plus-icon.svg');
  background-size: contain;
  border: none;
}

.label-wrapper .config-icon-plus:hover {
  cursor: pointer;
  background: url('../../../../assets/plus-icon-hover.svg');
  background-size: contain;
}

.label-wrapper .wrapper-title-icon-plus {
  display: flex;
  align-items: center;
}

.label-wrapper .text-label {
  text-align: left;
  color: var(--dark-grey);
}
