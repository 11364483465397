.small-orange-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
}

.small-orange-button:hover {
    background-color: #E0932F;
}

.small-orange-button:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}

.small-box-button {
    border: 1px solid;
    width: 24px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}