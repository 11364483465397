.contentContainer {
  margin: 5px;
  font-size: 12px !important;
  position: relative;
}


.k-upload {
  border-bottom-color: var(--primary-color);
}

.invalid-password-phrase {
  color: var(--red);
}

.selected-file-phrase {
  color: #646666;
}

.k-upload .k-upload-button>span::before {
  content: "Arraste ou clique para adicionar um arquivo";
  visibility: visible;
  font-size: 13px;
}