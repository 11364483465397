.contentContainer {
  margin: 5px;
  font-size: 12px !important;
  position: relative;
}


.k-upload {
  border-bottom-color: var(--primary-color);
}

.invalid-password-phrase {
  color: var(--red);
}

.selected-file-phrase {
  color: #646666;
}

.k-upload .k-upload-button>span::before {
  content: "Arraste ou clique para adicionar arquivos";
  visibility: visible;
  font-size: 13px;
}

.anexo-container {
  display: flex;
}

.anexo-esquerda {
  float: left;
  width: 455px;
  height: 479px;
  margin: 10px;
}

.anexo-direita {
  width: 455px;
  float: right;
  background-color: #e4e4e4;
  height: 479px;
  text-align: center;
  position: relative;
  margin: 10px;
  padding: 10px;
}

.anexo-esquerda .grid-inline tr td,
.anexo-esquerda .grid-inline tr th {
  border: 0px;
}

#file-icon .k-icon {
  font-size: 20px !important;
}

.selected-file-phrase {
  color: #646666;
  margin-top: 10px;
}