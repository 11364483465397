.popup-layout .k-window-titlebar {
  padding: 12px 20px;
  background-color: var(--primary-color);
}

.popup-layout .k-window-titlebar .k-window-title {
  font-weight: 400 !important;
  font-size: 24px !important;
  padding: 0;
}

.popup-layout .k-window-content {
  padding: 25px 40px;
}

.popup-layout .k-window-actions,
.popup-layout .k-window-actions .k-button {
  border: 0;
  border-radius: 50% !important;
  height: auto;
  margin: 0;
  min-width: unset;
  width: auto;
}

.popup-layout .k-window-actions .k-button * {
  color: white;
  font-size: 21px !important;
}

.popup-layout .k-window-actions .k-button:hover {
  background-color: white !important;
}

.popup-layout .k-window-actions .k-button:hover * {
  color: var(--primary-color);
}