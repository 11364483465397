.loginFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginFormWrapper button {
  color: white;
  font-size: medium !important;
  font-weight: bolder !important;
  border-radius: 50%;
  background-color: red;
  box-shadow: 0px 10px 2px 1px rgba(0, 0, 0, 0.2);
  border-width: 0;
  transition: 0.05s;
  cursor: pointer;
}

.loginFormWrapper button:active {
  background-color: rgb(204, 0, 0);
  margin-top: 10px !important;
  box-shadow: 0 0 0 0 black;
}

.loginFormWrapper button:active,
.loginFormWrapper button:focus {
  outline: none;
}
