#document-list .k-card-horizontal {
  width: calc(25% - 10px);
  min-width: 170px;
  height: 125px;
}

#document-list .k-card-horizontal .k-card-image {
  width: auto;
  height: 123px;
  padding: 21px 0px 21px 15px;
  opacity: 100;
}

#document-list .k-card-horizontal .k-vbox {
  width: 100%;
}

#document-list .k-card-body {
  text-align: left;
  padding: 51px 0px 0px 16px;
}

#document-list .k-card-title {
  font-size: 16px !important;
  font-weight: 700 !important;
}

#document-list .k-card-actions {
  padding: 0px 0px 5px 0px;
}

#document-list .k-card-actions .k-card-title {
  font-size: 18px !important;
  font-weight: 400 !important;
}

#document-list .k-card {
  box-shadow: 2px 2px 6px #cdcdcd;
}

#document-list .config-components-popup-button .k-fab-primary {
  background-color: #00a6d2;
  outline: #00a6d2;
}

#document-list .config-components-popup-button .k-fab-primary:hover {
  background-color: #008eb6;
  outline: #008eb6;
}

#document-list .k-card-avatar,
.k-card .k-avatar {
  margin-right: 16px;
  width: 35px;
  height: 35px;
  flex-basis: 35px;
}