.fill-100-field {
  width: 100%;
}

.fill-90-field {
  width: 90%;
}

.fill-80-field {
  width: 80%;
}

.fill-70-field {
  width: 70%;
}

.fill-60-field {
  width: 60%;
}

.fill-50-field {
  width: 50%;
}

.fill-40-field {
  width: 40%;
}

.fill-30-field {
  width: 30%;
}

.fill-25-field {
  width: 25%;
}

.fill-20-field {
  width: 20%;
}

.fill-16-field {
  width: 16%;
}

.fill-15-field {
  width: 15%;
}

.fill-10-field {
  width: 10%;
}

.fill-100-field-tooltip {
  width: calc(100% - 29px);
}

.fill-33-field {
  width: 33.33%;
}

.fill-66-field {
  width: 66.67%;
}