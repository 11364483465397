:root {
  --toastify-color-success: rgba(77, 185, 106, 0.8);
  --toastify-color-info: rgba(0, 166, 210, 0.8);
  --toastify-color-warning: rgb(255, 200, 0);
  --toastify-color-error: rgba(255, 59, 61, 0.8);

  --toastify-toast-width: 412px;
  --toastify-toast-min-height: 102px;
  --toastify-toast-max-height: 102px;
}

.Toastify__toast-body,
.Toastify__toast-body * {
  margin: 0;
  padding: 0;
  align-items: initial;
}

.Toastify__toast {
  border-radius: 0;
  padding: 20px;
  cursor: auto;
}

.Toastify__toast-container {
  padding: 0px;
}