.identification-user-modal {
  padding: 5px 20px 0px 20px;
}

.user-modal-avatar {
  margin-right: 0;
  display: flex;
}

.identification-user-avatar-sup {
  display: flex;
}

.identification-user-infos-avatar {
  display: flex;
  flex-direction: column;
}

.identification-user-infos {
  display: flex;
  flex-direction: column;
}

.identification-top-infos {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 10px;
}

#line-modal {
  width: 56%;
  border-bottom: #92b0ba 1px solid;
}

.span-cinza {
  color: var(--dark-grey);
  font-size: 16px !important;
}

.span-preto {
  color: var(--black);
  font-size: 14px !important;
}

.identification-user-general {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}