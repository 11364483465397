.drop-field-wrapper .drop-down-list-wrapper {
  width: 100%;
}

.drop-field-wrapper .wrapper-input {
  display: flex;
}

.drop-field-wrapper .wrapper-input>*+* {
  margin-left: 12px;
}

.drop-field-wrapper .wrapper-input .k-icon.k-i-pencil {
  background-color: var(--primary-color);
  height: auto;
  width: 30px;
  color: #fff;
  cursor: pointer !important;
}

.drop-field-wrapper .wrapper-input .k-icon.k-i-pencil:hover {
  background-color: var(--primary-color);
}


.k-list .k-item.k-state-focused {
  background-color: var(--primary-color) !important;
  box-shadow: inset 0 0 0 2px var(--primary-color);
  color: white;
}

.k-icon.k-i-close {
  display: none !important;
}