.table {
  overflow: auto;
}

.table * {
  font-size: 14px !important;
}

.table .k-grid-header,
.table .k-grid-footer {
  background-color: var(--primary-color);
}

/* * Estiliza��o de centraliza��o de c�lulas */
.table .k-checkbox,
.table .k-grid-header th.k-header.radio-button>span,
.table td.radio-button>* {
  display: block;
  margin: auto;
}

/* * Estiliza��es comuns */
.table tr td,
.table tr th {
  padding: 12px;
  border-color: #e4e4e4;
}

/* * Estiliza��o dos headers */
.table .k-grid-header,
.table .k-grid-header th.k-header {
  border-bottom-width: 0;
}

.table .k-grid-header th.k-header {
  background-color: #e3a14b;
  color: #ffffff;
  white-space: unset;
  vertical-align: middle;
}

.table .k-grid-header th.k-header>span.k-link {
  font-weight: 700 !important;
  padding: 14px 15px;
  /* cursor: inherit !important; comentado para habilitar ordena��o*/
  margin: 0;
  padding: 0 !important;
}

/* * Estiliza��o das c�lulas */
.table tr td {
  color: #333;
  border-bottom-width: 1px !important;
  background-color: white !important;
  white-space: nowrap;
  line-height: 10px !important;
}

.table tr:hover>td {
  background-color: #EDEDED !important;
  cursor: pointer;
}

/* * Estiliza��o dos radioButtons */
.table .k-grid-header th.k-header.radio-button>span {
  width: fit-content;
}

/* * Estiliza��o dos checkbox */
.table .k-checkbox,
.table .k-checkbox:focus {
  background-image: linear-gradient(to right, white, white);
  border: 1px solid #333 !important;
  border-radius: 0;
}

.table .k-checkbox::before {
  display: none;
}

.table .k-checkbox:checked {
  background-image:
    linear-gradient(to right, var(--primary-color), var(--primary-color)),
    linear-gradient(to right, white, white);
  background-size: 10px 10px, cover;
}

/* * Estiliza��o das colunas de dinheiro */
.table .cash-column {
  text-align: right;
}

.table .center-column {
  text-align: center;
}

.table .right-column {
  text-align: right;
}

/* Estiliza��o dos icones de ordena��o */
.table .k-grid-header .k-i-sort-asc-sm {
  color: white;
}

.table .k-grid-header .k-i-sort-desc-sm {
  color: white;
}

.table .k-grid-footer {
  background-color: white;
}

.table .k-button {
  margin-left: 5px;
  min-width: unset !important;
  width: calc(1.4285714286em + 12px);
  height: calc(1.4285714286em + 12px);
  padding: 4px 4px;
  border: 1px solid #cdcdcd !important;
}

.table .k-datepicker .k-select {
  background-color: white !important;
  color: black !important;
  border-color: white !important;
}