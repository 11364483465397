.logo {
  display: flex;
}

.logo .k-avatar {
  margin-right: 18px;
}

.logo .k-avatar span.k-avatar-text {
  font-size: 16px !important;
  display: flex;
}

.logo .k-avatar {
  width: 270px;
  height: 100px;
}

/* .logo .k-avatar img {
  max-width: 100%;
  height: auto;
} */

.logo .text-logo {
  margin: auto 0;
}

.logo .text-logo h4 {
  margin: 0 0 10px;
}

.logo .text-logo .buttons {
  display: flex;
}

.logo .buttons img {
  margin-right: 15px;
  height: 19px;
}

.logo .buttons>*+* {
  margin-left: 10px;
}

.logo .buttons .remove-button:hover {
  background-color: red;
  color: white;
}

.logo .k-avatar-solid.k-avatar-primary {
  color: #424242;
  border: 1px dashed #999999;
  text-align: center;
}