:root {
  --primary-color: #e3a14b;
  --primary-color-hover: #d6943e;
  --primary-color-dark: #c88732;
  --secondary-color: #ffe1ba;
  --white: #f6f6f6;
  --dark-grey: #646666;
  --black: #000000;
  --green: #00b248;
  --red: #ff3b3d;
  --strong-red: #e12022;
}

* {
  font-family: 'Lato', Helvetica, sans-serif, Arial;
  font-weight: 400 !important;
  font-size: 16px !important;
}

*::selection {
  background-color: #3390ff !important;
  color: var(--white) !important;
}

*:active,
*:focus,
.k-state-focus {
  outline: none !important;
  box-shadow: none !important;
}

html,
body,
#root,
.App {
  height: 100%;
}

h1 {
  font-weight: 300 !important;
  font-size: 24px !important;
  margin: 0;
  /* padding: 0.67em 0; */
}

button {
  padding: unset;
}

body>.k-animation-container {
  z-index: 10010 !important;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: var(--primary-color) !important;
}

.k-button {
  background-image: none !important;
  border-radius: 0 !important;
  font-weight: 600 !important;
  padding: 5px 12px 6px 12px;
  min-width: 100px;
}

.k-textarea {
  width: 100%;

}

.dark-grey {
  color: var(--dark-grey);
}

.calendar-invoice .k-button {
  min-width: unset !important;
}

.k-avatar-md {
  flex-basis: auto !important;
  width: 37px;
  height: 37px;
}

.k-textbox {
  width: 100%;
  height: 30px;
  outline: 0;
  padding: 2px 0;
}

.k-textbox:disabled,
.k-textbox[disabled],
.k-textbox.k-state-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
  background-color: #d6d6d6;
}

.k-textbox::selection,
.k-textbox .k-input::selection {
  color: #020202 !important;
  background-color: #cac2c0 !important;
}

.k-textarea::selection,
.k-textarea .k-input::selection {
  color: #020202 !important;
  background-color: #cac2c0 !important;
}

/* TODO mudar de lugar, � para os toggles */
.k-panelbar .k-panelbar-header .k-link {
  cursor: pointer !important;
}

/* TODO Remover daqui pra baixo*/
.general-data-row-forms-limp .k-button {
  margin-left: 15px;
  margin-bottom: 20px;
}

.button-popup-custcenter-confirm .k-button {
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.k-avatar {
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

.k-maskedtextbox {
  padding: 4px 8px;
}

.Toastify__toast {
  max-height: none;
}

.k-datepicker .k-select {
  background-color: white !important;
  color: var(--primary-color) !important;
  border-color: white !important;
}

.k-datepicker::selection,
.k-datepicker .k-input::selection {
  color: #020202 !important;
  background-color: #cac2c0 !important;
}

.k-picker-wrap {
  height: 30px;
}

.k-picker-wrap .k-input {
  height: 28px;
  outline: 0;
}

.k-picker-wrap {
  border: 1px solid #cdcdcd !important;
}

.k-picker-wrap:hover {
  border-color: #6f6f6f !important;
}

.k-picker-wrap.k-state-focused {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 2px rgb(0 0 0 / 25%) !important;
}