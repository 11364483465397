.month-calendar-field {
  width: unset;
}

.month-calendar-field input,
.month-calendar .react-datepicker__month .react-datepicker__month-text,
.month-calendar .react-datepicker__month .react-datepicker__quarter-text {
  text-transform: capitalize;
}

.month-calendar-field:hover input {
  cursor: pointer;
}

.month-calendar {
  padding: 0 !important;
}

.month-calendar * {
  margin: 0 !important;
}

.month-calendar span.react-datepicker__navigation-icon--previous,
.month-calendar span.react-datepicker__navigation-icon--next {
  width: 0;
  height: 0;

  transform: unset;

  border: 6px solid transparent;
}

.month-calendar span.react-datepicker__navigation-icon--previous {
  border-right-color: var(--primary-color);
}

.month-calendar span.react-datepicker__navigation-icon--next {
  border-left-color: var(--primary-color);
}

.month-calendar .react-datepicker__month .react-datepicker__month-text,
.month-calendar .react-datepicker__month .react-datepicker__quarter-text {
  display: inline-flex;
  height: 70px;
  width: 70px;
  font-weight: 300 !important;
  align-items: center;
  justify-content: center;
}

.month-calendar .react-datepicker__header,
.month-calendar .react-datepicker__navigation {
  background-color: #fff;
  border-bottom: none;
  height: 2.429em;
  line-height: 2.429em;
  margin: 5px 0 !important;
  padding: 0;
}

.month-calendar .react-datepicker__month--selected,
.month-calendar .react-datepicker__month-text--keyboard-selected {
  background-color: transparent;
  color: #000;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px var(--primary-color);
}

.month-calendar .react-datepicker__month-text,
.month-calendar .react-datepicker__month-text:hover {
  border-radius: 0;
}

.month-calendar .react-datepicker__month-text:hover {
  background-color: #F4F4F4 !important;
}

.month-calendar .react-datepicker__month-text:active {
  background-color: var(--secondary-color) !important;
}

.month-calendar .react-datepicker__navigation-icon::before,
.month-calendar .react-datepicker__triangle{
  display: none;
}

.month-calendar .react-datepicker {
    border-radius: 0;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 18%);
}
