#settings-page .tables-information {
  color: var(--dark-grey);
}

.k-upload .k-upload-button {
  width: 100%;
  padding: 8px;
  margin: 0 3% 0 3%;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, .15);
}

.k-upload .k-upload-button>span {
  visibility: hidden;
  margin-left: 85px;
}

.k-upload .k-upload-button>span::before {
  content: "Arraste ou clique para adicionar um Certificado Digital Modelo A1";
  visibility: visible;
  font-size: 13px;
}

.red-button {
  flex-shrink: 0 !important;
}

.link-settings {
  color: var(--primary-color);
}

.side-button {
  margin-left: 20px;
}

.settings-page #container-grid {
  display: grid;
  grid-template-columns: repeat(2, 35%);
  grid-gap: 50px;
  width: 100%;
}

#card-list .buttons-card-list {
  display: flex;
  justify-content: flex-end;
}