.side-bar-wrapper>aside {
  background-color: var(--primary-color);
  height: 100vh;
  width: 302px;
  overflow: hidden;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.side-bar-wrapper .side-bar-menu {
  transition: 0.3s linear;
}

.side-bar-wrapper>div {
  height: 100vh;
  width: 302px;
}

.side-bar-wrapper .hidden {
  width: 0;
}

.side-bar-wrapper aside>img {
  width: 242px;
  height: auto;
  padding: 20px 10%;

  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
}

.side-bar-wrapper .k-image {
  width: 25px;
  height: 25px;
  padding-right: 5px;
}

.side-bar-wrapper .k-panelbar {
  line-height: 2.2 !important;
  border-width: 0 !important;
  border-style: none !important;
}

.side-bar-wrapper .k-panelbar>.k-item {
  border-style: none;
}

.k-panelbar .k-link {
  background-color: var(--primary-color) !important;
  color: white !important;
  text-transform: none !important;
  font-size: 20px;
}

.side-bar-wrapper .k-panelbar .k-link:hover,
.side-bar-wrapper .k-panelbar .k-link.k-state-selected,
.side-bar-wrapper .k-item.k-state-expanded .k-link {
  background-color: var(--primary-color-hover) !important;
}

.side-bar-wrapper .k-item.k-state-expanded .k-level-1 .k-link:hover,
.side-bar-wrapper .k-item.k-state-expanded .k-level-1 .k-link.k-state-selected {
  background-color: var(--primary-color-dark) !important;
}

.side-bar-wrapper .k-icon {
  color: white !important;
  font-size: 25px !important;
}

.k-panelbar .k-group>.k-item.k-level-1 .k-link,
.k-panelbar .k-panelbar-group>.k-panelbar-item.k-level-1 .k-link {
  padding-left: 68px;
}

.side-bar-wrapper .k-panelbar>.k-item>.k-link,
.side-bar-wrapper .k-panelbar>.k-panelbar-header>.k-link {
  padding-left: 34px !important;
}