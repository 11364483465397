#contract-list .buttons-contract-list {
  display: flex;
  justify-content: flex-end;
}

#contract-list .buttons-contract-list>*+* {
  margin-left: 8px;
}

#contract-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.k-i-circle::before {
  font-size: xx-large;
}

#combobox-pagination {
  width: 100;
  padding-top: 5px;
  background-color: '#F6F6F6';
  border: 'none';
}

.big-icon {
  font-size: 24px !important;
  color: #e3a14b !important;
}