#product-registration .buttons-product-registration {
    display: flex;
    justify-content: flex-end;
}

#product-registration .buttons-product-registration>*+* {
    margin-left: 8px;
}

#product-registration {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.product-container {
    display: flex;
}

.product-esquerda {
    float: left;
    width: 80%;
}

.product-direita {
    float: right;
    width: 20%;
    min-width: 240px;
    margin-left: 57px;
}