#attendance-list .buttons-attendance-list {
  display: flex;
  justify-content: flex-end;
}

#attendance-list .buttons-attendance-list>*+* {
  margin-left: 8px;
}

#attendance-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.k-i-circle::before {
  font-size: xx-large;
}