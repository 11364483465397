.form-row {
  display: inline-flex;
  align-items: flex-end;
  margin-top: 20px;
  width: 100%;
}

.form-row.half-size {
  width: 50%;
}

.form-row.without-margin-top {
  margin-top: 0;
}

.form-row>*+* {
  margin-left: 57px;
}

.form-row>*:not(:last-child) {
  flex-shrink: 0;
}

.form-row.with-shrink>* {
  flex-shrink: 1;
}

.form-row.space-10>*+* {
  margin-left: 10px;
}

.form-row.space-15>*+* {
  margin-left: 15px;
}

.form-row.justify-end {
  justify-content: flex-end;
}

.form-row.space-between {
  justify-content: space-between;
}

.form-row.align-center {
  align-items: center;
}

.form-row.margin-top-40 {
  margin-top: 40px;
}

.form-row.align-top {
  align-items: flex-start;
}