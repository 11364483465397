#container-grid {
  display: grid;
  grid-template-columns: repeat(2, 35%);
  grid-gap: 50px;
  width: 100%;
}

#page-title {
  font-weight: 300 !important;
  font-size: 24px !important;
  color: #646666;
  margin-bottom: 45px;
}

.div-link {
  text-align: end;
}