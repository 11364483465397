.navbar-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100vw - 302px);
  min-width: 1000px;
  height: 100px;
  padding: 0 33px;

  transition: 0.3s linear;

  background-color: white;
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
  z-index: 999;
}

.navbar-wrapper nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-wrapper nav,
.navbar-wrapper nav > *,
.navbar-wrapper nav > * > * {
  height: 100%;
}

.navbar-wrapper .menu-item-wrapper,
.navbar-wrapper .menu-item-wrapper ul,
.navbar-wrapper .menu-item-wrapper ul li,
.navbar-wrapper .menu-item-wrapper ul li .k-menu-link,
.navbar-wrapper .menu-item-wrapper ul li .k-menu-link .navbar-item,
.k-menu-group .k-menu-item {
  width: 245px;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.navbar-wrapper .menu-item-wrapper ul li .k-menu-link > span {
  color: var(--primary-color);
}

.navbar-wrapper .menu-item-wrapper ul li {
  box-sizing: border-box;
  padding: 10px;
}

.navbar-wrapper .navbar-item ~ span {
  margin: 0;
}

.navbar-wrapper .menu-item-wrapper ul li:hover,
.navbar-wrapper .menu-item-wrapper ul li[aria-expanded='true'] {
  background-color: #eeeff1;
  cursor: pointer;
}

.navbar-wrapper .k-menu-link {
  padding: 0;
}

.navbar-100 {
  max-width: 100vw;
  left: 0;
}

#controle-expansao-menu {
  background-color: white;
  width: 21px;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

#controle-expansao-menu span {
  height: 4px;
  width: 100%;
  background: var(--primary-color);
}

#controle-expansao-menu span + span {
  margin-top: 5px;
}

.k-menu-group .k-menu-item,
.k-menu-group .k-menu-item .navbar-item > span {
  background-color: #eeeff1;
  color: black;
}

.k-menu-group .k-menu-item:hover,
.k-menu-group .k-menu-item:hover .navbar-item > span {
  background-color: var(--primary-color);
  color: white;
}
