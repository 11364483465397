.formField,
.k-numeric-wrap,
.k-dropdown-wrap {
  border: 1px solid #cdcdcd !important;
  background-color: white !important;
  border-radius: 0 !important;
  background-image: none !important;
  line-height: 1.4285714286;
}

.formField:hover,
.k-numeric-wrap:hover,
.k-dropdown-wrap:hover {
  border-color: #6f6f6f !important;
}

.formField:focus-within,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 2px rgb(0 0 0 / 25%) !important;
}

.k-i-arrow-s::before {
  color: var(--primary-color);
}

.k-i-arrow-n::before {
  color: var(--primary-color);
}

.k-combobox .k-select {
  background-color: white;
}

.k-dropdown-wrap {
  height: 30px;

}

.k-dropdown-wrap .k-input {
  height: 28px;
  outline: 0;
}