.aliquot .k-grid-header {
    padding: 0 !important;
}

.aliquot .k-grid-content {
    overflow-y: visible;
    overflow-x: visible;
}

.aliquot .k-grid {
    overflow: hidden;
}

.aliquot .k-grid .k-grid-edit-cell {
    padding: 1px;
}