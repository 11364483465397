form.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

form.LoginForm > * + * {
  margin-top: 8px;
}

form.LoginForm input {
  color: black;
}

form.LoginForm button {
  color: white;
  font-size: xx-large !important;
  font-weight: bolder !important;
  border-radius: 50%;
  background-color: red;
  box-shadow: 0px 10px 2px 1px rgba(0, 0, 0, 0.2);
  border-width: 0;
  transition: 0.05s;
  cursor: pointer;
}
