.grid-rowspan {
  overflow: auto;
}

.grid-rowspan * {
  font-size: 14px !important;
}

.grid-rowspan .k-grid-header,
.grid-rowspan .k-grid-footer {
  background-color: var(--primary-color);
}

/* * Estiliza��o de centraliza��o de c�lulas */
.grid-rowspan .k-checkbox,
.grid-rowspan .k-grid-header th.k-header.radio-button>span,
.grid-rowspan td.radio-button>* {
  display: block;
  margin: auto;
}

/* * Estiliza��es comuns */
.grid-rowspan tr td,
.grid-rowspan tr th {
  padding: 12px;
  border-color: #e4e4e4;
}

/* * Estiliza��o dos headers */
.grid-rowspan .k-grid-header,
.grid-rowspan .k-grid-header th.k-header {
  border-bottom-width: 0;
}

.grid-rowspan .k-grid-header th.k-header {
  background-color: #e3a14b;
  color: #ffffff;
  white-space: unset;
  vertical-align: middle;
}

.grid-rowspan .k-grid-header th.k-header>span.k-link {
  font-weight: 700 !important;
  padding: 14px 15px;
  /* cursor: inherit !important; comentado para habilitar ordena��o*/
  margin: 0;
  padding: 0 !important;
}

/* * Estiliza��o das c�lulas */
.grid-rowspan tr td {
  color: #333;
  border-bottom-width: 1px !important;
  background-color: white !important;
  white-space: nowrap;
  line-height: 10px !important;
}

.grid-rowspan tr:hover>td {
  background-color: #EDEDED !important;
  cursor: pointer;
}

/* * Estiliza��o dos radioButtons */
.grid-rowspan .k-grid-header th.k-header.radio-button>span {
  width: fit-content;
}

/* * Estiliza��o dos checkbox */
.grid-rowspan .k-checkbox,
.grid-rowspan .k-checkbox:focus {
  background-image: linear-gradient(to right, white, white);
  border: 1px solid #333 !important;
  border-radius: 0;
}

.grid-rowspan .k-checkbox::before {
  display: none;
}

.grid-rowspan .k-checkbox:checked {
  background-image:
    linear-gradient(to right, var(--primary-color), var(--primary-color)),
    linear-gradient(to right, white, white);
  background-size: 10px 10px, cover;
}

/* * Estiliza��o das colunas de dinheiro */
.grid-rowspan .cash-column {
  text-align: right;
}

/* Estiliza��o dos icones de ordena��o */
.grid-rowspan .k-grid-header .k-i-sort-asc-sm {
  color: white;
}

.grid-rowspan .k-grid-header .k-i-sort-desc-sm {
  color: white;
}

.grid-rowspan .k-grid-footer {
  background-color: white;
}

#remove-icon .k-fab-icon {
  width: 21px;
  height: 21px;
  font-size: 10px !important;
}

#remove-icon .k-fab-circle.k-fab-sm {
  width: calc(1px);
  height: calc(1px);
}

.grid-rowspan .k-numerictextbox .k-select {
  display: none;
}