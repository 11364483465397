.calendar-invoice {
  display: flex;
  align-items: center;
}

.calendar-invoice>*+* {
  margin-left: 4px;
}

.calendar-invoice>input {
  height: 26px;
  width: 26px;

  border: none;

  background-size: contain !important;
}

.calendar-invoice>input:hover {
  cursor: pointer;
}

/* TODO isolate this */
.calendar-invoice .previus-more-icon {
  background: url('../../assets/previus-more.svg');
}

.calendar-invoice .previus-more-icon:hover {
  background: url('../../assets/previus-more-hover.svg');
}

.calendar-invoice .previus-icon {
  background: url('../../assets/previus.svg');
}

.calendar-invoice .previus-icon:hover {
  background: url('../../assets/previus-hover.svg');
}

.calendar-invoice .advance-icon {
  background: url('../../assets/advance.svg');
}

.calendar-invoice .advance-icon:hover {
  background: url('../../assets/advance-hover.svg');
}

.calendar-invoice .advance-more-icon {
  background: url('../../assets/advance-more.svg');
}

.calendar-invoice .advance-more-icon:hover {
  background: url('../../assets/advance-more-hover.svg');
}