.container {
  background-color: white;
  padding: 15px 20px;
  box-shadow: 2px 3px 7px rgb(0 0 0 / 15%);
  height: 220px;
}

.header {
  display: flex;
  align-items: center;
  height: 30px;
}

.text-card {
  margin: 30px 0px 0px;
  height: 140px;
}

.Title {
  margin-left: 15px;
  font-size: 19px !important;
}

.Title,
.text-card,
.link {
  color: #646666;
}

.helpcard-image {
  width: 25px;
  height: 25px;
}

.link {
  color: var(--primary-color);
  display: block;
  text-align: end;
  height: 20px;
}