.radio-label {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px !important;
  line-height: 12px;
  color: #646666;
}

.radio-label input {
  display: none;
}

.radio-label .radio-icon {
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  border: 1px solid #646666;
  border-radius: 50%;
  padding: 3px;
  background-clip: content-box;
}

.radio-label:hover {
  cursor: pointer !important;
}

.radio-label:hover input ~ .radio-icon {
  border-color: var(--primary-color);
}

.radio-label input:checked ~ .radio-icon {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
