.red-button {
    color: #6f6f6f;
    background-color: #fff;
    border: 1px solid #cdcdcd;
    color: #6f6f6f;
}

.red-button:hover {
    background-color: var(--strong-red);
    border: 1px solid var(--strong-red);
    color:white;
}