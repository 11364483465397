.orange-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
}

.orange-button:hover {
    background-color: #E0932F;
}

.orange-button:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
}