.input-field {
  display: flex;
  align-items: stretch;
}

.input-field .align-right {
  text-align: right;
}

.input-field .external-icon,
.input-field .internal-icon {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.input-field .external-icon {
  justify-content: center;
  width: 30px;
  background-color: var(--primary-color);
  color: #fff;
}

.input-field .internal-icon {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  color: var(--primary-color);
  height: auto;
  pointer-events: none;
}

.input-field .icon-info {
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.input-field .formField,
.input-field input {
  width: 100%;
}

.input-field .k-maskedtextbox.formField input {
  padding: 0;
  border: 0;
  height: calc(100% - 2px);
  background-color: transparent;
}

.input-field .formField {
  order: 1;
}

.input-field .external-icon,
.input-field .icon-info {
  order: 2;
}

.input-field .external-icon.money-icon {
  order: 0;
}

.input-field .money-input.positive~.external-icon.money-icon {
  background-color: var(--green);
}

.input-field .money-input.negative~.external-icon.money-icon {
  background-color: var(--red);
}