/* .Toastify__toast-body is container div classname
   of this component */
.Toastify__toast-body h3 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.Toastify__toast-body p {
  font-weight: 300 !important;
}
