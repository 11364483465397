#taxClass-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.config-components-popup-button *,
ul.k-fab-items * {
  color: white;
}

.config-components-popup-button .k-fab-primary {
  background-color: var(--primary-color);
}

.config-components-popup-button .k-fab-primary:hover {
  background-color: var(--primary-color-hover);
}

.config-components-popup-button .k-fab-icon {
  width: 43px;
  height: 43px;
  font-size: 20px !important;
}

.config-components-popup-button .k-pos-fixed {
  position: inherit !important;
}

.config-components-popup-button .k-fab-md {
  padding: 0;
}