.k-checkbox,
.k-checkbox:focus {
    background-image: linear-gradient(to right, white, white);
    border: 1px solid #333 !important;
    border-radius: 0;
}

.k-checkbox::before {
    display: none;
}

.k-checkbox:checked {
    background-image:
        linear-gradient(to right, var(--primary-color), var(--primary-color)),
        linear-gradient(to right, white, white);
    background-size: 10px 10px, cover;
}

.k-checkbox-label {
    color: var(--dark-grey);
}