.photo {
  display: flex;
}

.photo .k-avatar {
  margin-right: 18px;
}

.photo .k-avatar,
.photo .k-avatar img {
  flex-basis: 136px;
  height: 136px;
}

.photo .k-avatar span.k-avatar-text {
  font-size: 110px !important;
  display: flex;
}

.photo .text-photo {
  margin: auto 0;
}

.photo .text-photo h4 {
  margin: 0 0 10px;
}

.photo .text-photo .buttons {
  display: flex;
}

.photo .buttons img {
  margin-right: 15px;
  height: 19px;
}

.photo .buttons > * + * {
  margin-left: 10px;
}

.photo .buttons .remove-button:hover {
  background-color: red;
  color: white;
}
